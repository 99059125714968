import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import UserList from '../MinecraftUserList/UserList'
import MinecraftModal from '../MinecraftModal/MinecraftModal'
import WhitelistBlurb from '../WhitelistBlurb/WhitelistBlurb'
import Heading from '../Heading/Heading'

import './Minecraft.scss'

export default function Minecraft() {
  const navigate = useNavigate()
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [discordUser, setDiscordUser] = useState({})

  function completeLogin() {
    if (accessToken === '' ) {
      const fragment = new URLSearchParams(window.location.hash.slice(1))
      const token = fragment.get('access_token')

      setAccessToken(token)
    }

    if (accessToken !== '') {
      navigate('/goochcraft')
      setLoggedIn(true)

      fetch('https://discord.com/api/users/@me', {
        headers: { authorization: `Bearer ${accessToken}` },
      }).then(result => result.json())
        .then(response => {
          console.log(response)
          setDiscordUser({
            username: response.username,
            avatar: response.avatar,
            _id: response.id
          })
        })

        setTimeout(() => {
          setModalOpen(true)
        }, 500)
    }
  }

  useEffect(() => {
    if (window.location.hash !== '') {
      completeLogin()
    }

  }, [accessToken])

  function closeModal() {
    setModalOpen(false)
    window.location.reload()
  }


  function showModal() {
    if (isModalOpen === true) {
      return <MinecraftModal onClickEvent={() => closeModal()} discordUser={discordUser} />
    }
  }

  return (
    <div className='Minecraft'>
      
      <Heading
        isVisible={isVisible}
        setVisible={setVisible}
        setModalOpen={setModalOpen}
        isLoggedIn={isLoggedIn}
      />

      <WhitelistBlurb />
      
      <UserList />

      { showModal() }

    </div>
  )
}
