import React from 'react'
import './User.scss'

export default function User(props) {
    const { _id, discord, twitch, minecraft } = props.props

    return (
        <div id={_id} className='crafter'>
            <div className='user-cell'>
                <p>{minecraft}</p>
            </div>
            <div className='user-cell'>
                <p>{discord}</p>
            </div>
            <div className='user-cell'>
                <p>{twitch}</p>
            </div>
        </div>
    )
}
