import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import TorisWebsite from './TorisWebsite/TorisWebsite'
import Minecraft from './Minecraft/MinecraftHome/Minecraft'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={ <TorisWebsite/> } />
        <Route exact path="/goochcraft" element={ <Minecraft/> } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
