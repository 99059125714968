import './AnimatedBackground.scss'

function Blob(num) {
    return (
        <div id={`blob-${num}`} className="blobs"></div>
    )
}

export default function AnimatedBackground() {
    let blobs = []

    for (var num = 0; num < 51; num++) {
        blobs.push(Blob(num))
    }

    return (
        <div className="AnimatedBackground">
            <div className="background-blur"></div>
            {blobs}
        </div>
    )
}
