import React from 'react'

import './WhitelistBlurb.scss'

export default function WhitelistBlurb() {
  return (
    <div>
        <h3 className='whitelist-blurb'>✨ Goochcraft on Java 1.20.2 ✨</h3>
        <p className='whitelist-blurb'>If ya wanna play, click the "Verify Me!" button to connect your Discord. Discord connection is required to verify that you're a member of the community. Once you submit the application with your information, it should appear in the list below. Tori will be notified that there's a new request, and she'll manually whitelist you when she can.</p>
        <p className='whitelist-blurb'>Ping Tori on Discord (@victowwia) to tell her to stop frickin around and whitelist you already!</p>
    </div>
  )
}
