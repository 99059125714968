import React, { useRef, useEffect } from 'react'

import ModalForm from '../ModalForm/ModalForm'

import './MinecraftModal.scss'


export default function MinecraftModal(props) {
  const { onClickEvent, discordUser } = props
  const domRef = useRef()

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') { onClickEvent() }
    })
  }, [])

  return (
    <div className='modal-bg'>
      <div ref={domRef} className='modal'>

        <div className='modal-heading'>
          <h1>Request Whitelisting</h1>
          <button onClick={onClickEvent}>X</button>
        </div>

        <ModalForm discordUser={discordUser} onClickEvent={onClickEvent} />

      </div>
    </div>
  )
}
