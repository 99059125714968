import './TorisWebsite.scss'

import Profile from '../Profile/Profile'
import AnimatedBackground from '../AnimatedBackground/AnimatedBackground'


function TorisWebsite() {
  console.log('get the fuck outta here bish 😤')
  return (
    <div>
      <div className='TorisWebsite'>
        
        <div>
          <h1>u found me</h1>
        </div>

        <Profile />
        
      </div>

      <AnimatedBackground />
    </div>
  )
}

export default TorisWebsite
