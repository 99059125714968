import './ProfileCard.scss'
import React, { Component }  from 'react'

export default class ProfileCard extends Component {
    constructor(props) {
        super(props)
        this.cardElement = React.createRef()
    }
    
    componentDidMount() { this.setEventListeners() }

    setEventListeners() {
        const card = this.cardElement.current;
        card.addEventListener('mouseover', () => {
            card.classList.remove('card-inactive')
            card.classList.add('card-active')
        })
        card.addEventListener('mouseout', () => {      
            card.classList.add('card-inactive')
            card.classList.remove('card-active')
        })
        card.addEventListener('animationend', () => {
            card.classList.remove('active', 'in-active')
        })
    }

    render() {
        const { id, h2, p, p2, link, icon, alt } = this.props

        if ( icon !== undefined ) {
            return (

                <div 
                    className={`profile-card ${id}`}
                    ref={this.cardElement}
                >
                    <a href={link}>
                        <img
                            src={`${icon}.png`}
                            alt={alt} 
                            className='icon'
                        />
                    </a>
                    <p>{p}</p>
                </div>
                
            )
        }
        return (
            <div 
                className={`profile-card ${id}`}
                ref={this.cardElement}
            >
                <div>
                    <p>{h2}</p>
                    <p>{p}</p>
                    <p>{p2}</p>
                </div>
            </div>
        )
    }
}