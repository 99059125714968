import React, { useState, useEffect } from 'react'

import User from '../User/User'

import './UserList.scss'

export default function UserList() {
  const [crafters, setCrafters] = useState([])

  useEffect(() => {
    fetchUsers()
  }, [])

  function fetchUsers() {
    fetch('https://crafters-043ba75e1622.herokuapp.com/crafters', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    })
      .then(response => response.json())
      .then(crafters => {
        setCrafters(crafters)
      })
  }

  return (
    <div className='user-section'>
      <div className='userlist'>

        <div className='th'>
          <div className='th-cell'>
            <h2>Minecraft Username</h2>
          </div>
          <div className='th-cell'>
            <h2>Discord Username</h2>
          </div>
          <div className='th-cell'>
            <h2>Twitch Username</h2>
          </div>
        </div>

        <div className='crafters'>
          {crafters.map(user => <User props={user} key={user._id} />)}
        </div>
      </div>
    </div>
  )
}
