import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'

import './ModalForm.scss'

export default class ModalForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            minecraftUser: '',
            discordUser: this.props.discordUser,
            twitchUser: '',
            onClickEvent: this.props.onClickEvent,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.sendPostRequest = this.sendPostRequest.bind(this)
    }

    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value })
    }
    
    handleSubmit(e) {
        e.preventDefault()
        this.sendPostRequest()
    }

    sendPostRequest() {
        let uri = 'https://crafters-043ba75e1622.herokuapp.com/crafters'

        let headers = new Headers()
        headers.append('Content-Type', 'application/x-www-form-urlencoded')

        let urlencoded = new URLSearchParams({
            'minecraft': this.state.minecraftUser,
            'discord': this.state.discordUser.username,
            'twitch': this.state.twitchUser
        })

        let requestOptions = {
        method: 'POST',
        headers,
        body: urlencoded,
        redirect: 'follow'
        }

        fetch(uri, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error))

        this.state.onClickEvent()
    }

    render() {
        const avatarIcon = `https://cdn.discordapp.com/avatars/${this.state.discordUser._id}/${this.state.discordUser.avatar}`
        return (
            <div className='form'>

            <div className='discord'>
                { this.state.discordUser &&
                    <p className='success'>Discord Connected!</p>
                }
                <div className='discord-profile'>
                    <img src={avatarIcon} alt='Discord User Icon' className='discord-icon' />
                    <p>Hi, {this.state.discordUser.username}!</p>
                </div>
            </div>
            
            <form>
            
            <div>
                <label className='text'>
                    Enter Minecraft Username
                </label>
                <input
                    type='text'
                    placeholder='xXcrafterXx'
                    name='minecraftUser'
                    value={this.minecraftUser}
                    onChange={this.handleChange}
                ></input>
            </div>


            <div>
                <label className='text'>
                    Enter Twitch Username
                </label>
                <input
                    type='text'
                    placeholder='googiestan420'
                    name='twitchUser'
                    value={this.twitchUser}
                    onChange={this.handleChange}
                ></input>
            </div>

            <div>
                <label className='checkbox'>
                <input
                    type='checkbox'
                    name='radio'
                    value={true}
                />
                I will not frick around too much and be an asshole. Also, I triple-checked that my information above is correct because I know Tori was too lazy to code a way for me to edit this data once I submit it. ;)
                </label>
            </div>

            <div>
                <button
                    className='submit'
                    onClick={this.handleSubmit}
                >
                    request
                </button>
            </div>

            </form>
        </div>
        )
    }
}
