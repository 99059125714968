import './Profile.scss'
import ProfileCard from '../ProfileCard/ProfileCard'

const profileData = [
    {
        id: 'name',
        h2: '👋',
        p: 'tori',
        p2: 'she/they',
        icon: undefined,
        alt: undefined
    },
    {
        id: 'stats',
        h2: '👉👈',
        p: '26',
        p2: 'seattle',
        icon: undefined,
        alt: undefined
    },
    {
        name: 'discord',
        h2: undefined,
        p: 'victowwia',
        p2: undefined,
        link: 'https://discord.com/users/222032069361008640',
        icon: 'disc',
        alt: 'discord'
    },
    {
        name: 'twitch',
        h2: undefined,
        p: 'aoit0ri',
        p2: undefined,
        link: 'https://twitch.tv/aoit0ri',
        icon: 'twitch',
        alt: 'twitch'
    }
]

function Profile() {
    return (
        <div className='Profile'>

            {profileData.map((data) => {
                return (
                
                <ProfileCard
                    id={data.id}
                    h2={data.h2}
                    p={data.p}
                    p2={data.p2}
                    link={data.link}
                    icon={data.icon}
                    alt={data.alt}
                />

            )})}
      </div>
    )
}

export default Profile