import React, { useRef } from 'react'

import './Heading.scss'

function LogInBtn(setModalOpen) {
    const uri = 'https://discord.com/api/oauth2/authorize?client_id=1158510261104296047&redirect_uri=https%3A%2F%2Ftoris.website%2Fgoochcraft&response_type=token&scope=identify'
    return <button className='modal-button' onClick={() => window.location.href = uri}>Verify Me!</button>
}

function WhitelistBtn(setModalOpen) {
    return <button className='modal-button' onClick={() => setModalOpen(true)}>Whitelist Me!</button>
}

export default function Heading(props) {
    const { isVisible, setVisible, setModalOpen, isLoggedIn } = props
    const domRef = useRef()

    function copyText() {
        navigator.clipboard.writeText('15.235.80.136:25584')
        setVisible(true)
        setTimeout(() => {
            setVisible(false)
        }, 1500)
    }

    function LoginOrModal() {
        if (isLoggedIn === false) {
            return LogInBtn(setModalOpen)
        } else {
            return WhitelistBtn(setModalOpen)
        }
    }

    return (
        <div className='heading'>
            
            <div className='ip-selection-plus-copied'>
                <div className='ip-section'>
                    <p>Join ➡️</p>
                    <h1 className='server-ip' onClick={() => {copyText()}}>
                    15.235.80.136:25584
                    </h1>
                </div>
                <p className={`copied ${isVisible ? 'is-visible' : ''}`} ref={domRef}>Copied!</p>
            </div>

            {LoginOrModal()}
        </div>
    )
}
